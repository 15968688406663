interface GetUserPermissionsMessage {
  type: 'getUserPermissions';
  payload: null;
}

function getUserPermissions(): Promise<string[]> {
  const response = new Promise<string[]>((resolve) => {
    function getUserPermissionsResponseListener(message: MessageEvent) {
      if (message.data.type === 'getUserPermissions') {
        resolve(message.data.payload);
        window.removeEventListener(
          'message',
          getUserPermissionsResponseListener
        );
      }
    }

    window.addEventListener('message', getUserPermissionsResponseListener);
  });

  const message: GetUserPermissionsMessage = {
    type: 'getUserPermissions',
    payload: null,
  };

  window.parent.postMessage(message, '*');

  return response;
}

export { getUserPermissions };
