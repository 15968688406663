type ExternalPage = 'Automations' | 'Payments' | 'Email_Marketing';

interface NavigateToExternalPageMessage {
  type: 'navigateToExternalPage';
  payload: ExternalPage;
}

function navigateToExternalPage(externalPageName: ExternalPage): void {
  const message: NavigateToExternalPageMessage = {
    type: 'navigateToExternalPage',
    payload: externalPageName,
  };

  window.parent.postMessage(message, '*');
}

export { navigateToExternalPage };
