interface NavigateToInternalPageMessage {
  type: 'navigateToInternalPage';
  payload: number;
}

function navigateToInternalPage(internalPageIndex: number): void {
  const message: NavigateToInternalPageMessage = {
    type: 'navigateToInternalPage',
    payload: internalPageIndex,
  };

  window.parent.postMessage(message, '*');
}

export { navigateToInternalPage };
