interface GetEditorTypeMessage {
  type: 'getEditorType';
  payload: null;
}

type EditorType = 'ADI' | 'EDITOR' | 'EDITORX' | 'NONE';

function getEditorType(): Promise<EditorType> {
  const response = new Promise<EditorType>((resolve) => {
    function getEditorTypeResponseListener(message: MessageEvent) {
      if (message.data.type === 'getEditorType') {
        resolve(message.data.payload);
        window.removeEventListener('message', getEditorTypeResponseListener);
      }
    }

    window.addEventListener('message', getEditorTypeResponseListener);
  });

  const message: GetEditorTypeMessage = {
    type: 'getEditorType',
    payload: null,
  };

  window.parent.postMessage(message, '*');

  return response;
}

export { getEditorType };
