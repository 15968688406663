interface GetInstanceMessage {
  type: 'getInstance';
  payload: null;
}

function getInstanceId(): Promise<string> {
  const response = new Promise<string>((resolve) => {
    function getInstanceListener(message: MessageEvent) {
      if (message.data.type === 'getInstance') {
        resolve(message.data.payload);
        window.removeEventListener('message', getInstanceListener);
      }
    }
    window.addEventListener('message', getInstanceListener);
  });

  const message: GetInstanceMessage = {
    type: 'getInstance',
    payload: null,
  };
  window.parent.postMessage(message, '*');
  return response;
}

export { getInstanceId };
