interface OpenCheckoutModalMessage {
  type: 'openCheckoutModal';
  payload: string;
}

function openCheckoutModal(orderParamsToken: string): Promise<string> {
  const response = new Promise<string>((resolve, reject) => {
    function openCheckoutModalResponseListener(message: MessageEvent) {
      // 'checkout-success' is here for compatability with Wolt app.
      // to remove it when they migrate to the new Dashboard SDK.
      if (message.data.type === 'checkoutModalSuccess' || message.data.type === 'checkout-success') {
        resolve(message.data.payload);
        window.removeEventListener('message', openCheckoutModalResponseListener);
      }
      // 'checkout-unfinished' is here for compatability with Wolt app.
      // to remove it when they migrate to the new Dashboard SDK.
      if (message.data.type === 'checkoutModalCancel' || message.data.type === 'checkout-unfinished') {
        reject('CANCELED_CHECKOUT');
        window.removeEventListener('message', openCheckoutModalResponseListener);
      }
    }

    window.addEventListener('message', openCheckoutModalResponseListener);
  });

  const message: OpenCheckoutModalMessage = {
    type: 'openCheckoutModal',
    payload: orderParamsToken,
  };

  window.parent.postMessage(message, '*');

  return response;
}

export { openCheckoutModal };
