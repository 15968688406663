interface MediaOptions {
  multiSelect?: Boolean;
  translation?: {
    title: String;
    submitButton: String;
  };
  path?: String;
}
type MediaCategory =
  | 'IMAGE'
  | 'VIDEO'
  | 'DOCUMENT'
  | 'MUSIC'
  | 'ICON_DOCUMENT'
  | 'ALL_MEDIA';

interface MediaMessagePayload {
  mediaCategory: MediaCategory;
  options?: MediaOptions;
}

interface OpenMediaMangerMessage {
  type: 'openMediaManager';
  payload: MediaMessagePayload;
}

function openMediaManager({
  mediaCategory,
  options,
}: MediaMessagePayload): Promise<object[] | string> {
  const response = new Promise<object[] | string>((resolve) => {
    function openMediaManagerResponseListener(message: MessageEvent) {
      if (message.data.type === 'openMediaManager') {
        resolve(message.data.payload);
        window.removeEventListener('message', openMediaManagerResponseListener);
      }
    }

    window.addEventListener('message', openMediaManagerResponseListener);
  });

  const message: OpenMediaMangerMessage = {
    type: 'openMediaManager',
    payload: {
      mediaCategory,
      options,
    },
  };

  window.parent.postMessage(message, '*');

  return response;
}

export { openMediaManager };
