interface GetLocaleMessage {
  type: 'getLocale';
  payload: null;
}

function getLocale(): Promise<string> {
  const response = new Promise<string>((resolve) => {
    function getLocaleResponseListener(message: MessageEvent) {
      if (message.data.type === 'getLocale') {
        resolve(message.data.payload);
        window.removeEventListener('message', getLocaleResponseListener);
      }
    }

    window.addEventListener('message', getLocaleResponseListener);
  });

  const message: GetLocaleMessage = {
    type: 'getLocale',
    payload: null,
  };

  window.parent.postMessage(message, '*');

  return response;
}

export { getLocale };
